import type { MetaDescriptor } from 'react-router';

export function getMeta(data: MetaDescriptor[]) {
  // @ts-expect-error - title
  const title = data.find((meta) => 'title' in meta)?.title as string;
  const description = data.find((meta) => 'name' in meta && meta.name === 'description') as
    | { name: string; content: string }
    | undefined;

  const DEFAULT: MetaDescriptor[] = [
    { name: 'og:title', content: title },
    { name: 'og:description', content: description?.content },
    { name: 'og:image', content: '/opengraph-image.jpg' },
    { name: 'og:image:width', content: '1900' },
    { name: 'og:image:height', content: '600' },
    { name: 'og:image:alt', content: 'Club Colette' },
    { name: 'og:site_name', content: 'Club Colette' },
    { name: 'og:url', content: 'https://colette.club' },
    { name: 'og:type', content: 'website' },
    { name: 'og:logo', content: '/icons/logo-152x152.png', size: '152x152' },
    { name: 'twitter:image', content: '/opengraph-image.jpg' },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:creator', content: '@coletteclub' },
    { name: 'twitter:site', content: '@coletteclub' },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description?.content },
  ];

  return mergeObjectsByKey(DEFAULT, data, ['name']);
}

function mergeObjectsByKey<T extends Record<string, any>>(defaults: T[], data: T[], key: string[]) {
  const merged = defaults.map((defaultMeta) => {
    const found = data.find((meta) => key.every((k) => defaultMeta[k] === meta[k]));

    if (found) {
      return { ...defaultMeta, ...found };
    }

    return defaultMeta;
  });

  // Add any additional meta tags that are not in the defaults
  data.forEach((meta) => {
    if (!defaults.some((defaultMeta) => key.every((k) => defaultMeta[k] === meta[k]))) {
      merged.push(meta);
    }
  });

  return merged;
}
